import { Image, Text, VStack ,Heading} from "@chakra-ui/react";
import * as React from "react";
interface CardProps {
  title: string;
  img: string;
  desc: string;
  tutor: string;
  score: string;
  accomplishment: string;
  college: string;
  degree: string,
}

export default function TutorInfo(props: CardProps) {
  const { title, img, desc, tutor, score, accomplishment,college, degree } = props;

  return (
    <>
      <VStack
        bg="white"
        overflow="hidden"
        position="relative"
        cursor="pointer"
        spacing="0px"
        ml="5%"
        mr="5%"
      >
        <Image
          borderRadius="100%"
          src={img}
          alt={title}
          objectFit="cover"
          w="200px"
          h="200px"
          mb="2%"
        />

        <Heading textAlign="center" color="black" fontSize="xl" >
          {title}
        </Heading>
        <Text textAlign="center" color="#043345" fontSize="lg" m="0">
          {tutor}
        </Text>
        <Text as="i" color="black" fontSize="sm" textAlign="center" >
          {college}
        </Text>
        <Text  color="black" fontSize="sm" textAlign="center">
          {accomplishment}
        </Text>
        <Text color="black" w="65%" fontSize="sm" textAlign="center">
          {desc}
        </Text>
      </VStack>
    </>
  );
}
