export const tutorsList = [
  {
    name: "Divij Garg",
    img: "images/divij.jpg",
    desc: "Hi! I'm a junior at the University of Illinois Urbana Champaign majoring in Computer Science + Mathematics. I've been a tutor for the past 4 years, working with students specifically on SAT preparation and Math.",
    tutor: "SAT Tutor, AP Calc BC, AP Physics, AP CSA",
    score: "SAT Score: 1600",
    accomplishment: "SAT Score: 1600",
    college: "B.S. in CS & Math @ University of Illinois Urbana Champaign",
    degree: "Computer Science & Mathematics",
  },
  {
    name: "Leisha Garg",
    img: "images/leisha.png",
    desc: "Hi! I'm a sophomore at the University of Illinois Urbana Champaign majoring in Computer Science and Statistics. For the past 3 years, I've been working with students to craft personalized SAT preparation plans.",
    tutor: "SAT Tutor, AP Calc BC, AP Physics",
    score: "SAT Score: 1570",
    accomplishment: "SAT Score: 1570",
    college: "B.S. in CS & Stats @ University of Illinois Urbana Champaign",
    degree: "Computer Science & Statistics",
  },
  {
    name: "Saurav Gupta",
    img: "images/saurav.jpg",
    desc: "Hey! I'm a first-year Master’s student at the University of Illinois at Urbana-Champaign, majoring in Analytical Statistics. I've passed several actuarial exams, showcasing his advanced mathematical skills. Additionally, I have experience tutoring in algebra and calculus, effectively helping students understand fundamental concepts.",
    tutor: "Finance, Calculus, Actuarial Science",
    score: "",
    accomplishment: "",
    college: "M.S. in Analytical Statistics @ University of Illinois Urbana Champaign",
    degree: "Analytical Statistics",
  },
  {
    name: "Rohan Kher",
    img: "images/rohan.jpeg",
    desc: "Hi! I’m an undergraduate who will be studying Physics at Brown in the fall. In the past, I’ve worked with students as a freelance tutor in science and math and as a teacher’s assistant in a freshman science class. In my free time, I like to read, work out, and watch sports",
    tutor: "SAT/ACT Tutor",
    score: "",
    accomplishment: "ACT Score: 36",
    college: "B.S. in Physics @ Brown University",
    degree: "Physics",
  },
  {
    name: "Thandiwe Ogbonna",
    img: "images/Thandiwe.jpeg",
    desc: "I am a professional tutor with over 10 years of experience helping students prepare for the SAT. I live in Orlando, FL, and enjoy reading and learning new things in my free time.",
    tutor: "SAT Tutor,  Creative writing, Language Arts, ACT, Algebra",
    score: "",
    accomplishment: "10+ Years of Experience",
    college: "B.A. in Economics @ Virginia Tech",
    degree: "Economics",
  },

  {
    name: "Pranav Meraga",
    img: "images/Pranav.png",
    desc: "I'm a sophomore at UC Berkeley studying aerospace engineering and computer science. I have been tutoring for 6 years, and am constantly looking to educate others to entrust curiosity within others while also teaching myself about new approaches of learning.",
    tutor: "SAT Tutor",
    score: "",
    accomplishment: "SAT Score: 1570",
    college: "B.S. in Aerospace Engineering @ UC Berkeley",
    degree: "Aerospace Engineering",
  },
  {
    name: "Chandler Mclarth",
    img: "images/Chandler.jpg",
    desc: "I graduated from Carroll University in 2019 with a B.S. in Biochemistry and have three years of tutoring experience. This fall, I will be earning my PhD in Biochemistry from the Medical College of Wisconsin.",
    tutor: "6th-8th Grade Biology, Chemistry, AP Biology, AP Chemistry",
    score: "",
    accomplishment: "",
    college: "PhD in Biochemistry @ Medical College of Wisconsin",
    degree: "Biochemistry",
  },
  {
    name: "Chika Okaneme",
    img: "images/chika.jpg",
    desc: "My emphasis on detail, clarity, and concision  leads to quality pieces of writing. My goal is to make every student's essay look its very best, so I hope to work with you soon!",
    tutor: "College Essay Tutor, Regents & AP English, Regents & AP US History, Regents Global History",
    score: "",
    accomplishment: "College Essay Tutor",
    college: "M.S. in Journalism @ Columbia University",
    degree: "M.S. Journalism",
  },
  {
    name: "Kevin Hutcherson",
    img: "images/kevin.jpg",
    desc: "As a tutor with over 10 years of experience in teaching and tutoring AP Chemistry, my main goal is to help students succeed. I foster a relaxed and supportive learning environment that encourages deep understanding of concepts rather than just rote memorization. By enhancing comprehension and nurturing a love for chemistry, I aim to make each session enjoyable and productive. This approach not only improves grades but also builds a solid foundation for future scientific pursuits.",
    tutor: "AP Chemistry Tutor",
    score: "",
    accomplishment: "10+ Years of Experience",
    college: "",
    degree: "",
  },
  {
    name: "Esha Mujumdar",
    img: "images/esha.jpg",
    desc: "Hi, I'm Esha and I'm a freshman studying Computer Science at UIUC this fall. Over the years, I’ve been tutoring various subjects, including math, science, Spanish, and SAT prep. I love helping students and seeing them reach their academic goals.",
    tutor: "SAT Tutor, AP Calc BC, Calculus 3, Algebra 2, Geometry",
    score: "SAT Score: 1560",
    accomplishment: "SAT tutor, AP physics, AP Math, Spanish",
    college:
      "B.S. in Computer Science @ University of Illinois Urbana Champaign",
    degree: "Computer Science",
  },
  {
    name: "Steven Ingebrightsen",
    img: "images/Steven.jpeg",
    desc: "Hello! My name is Steven and I am a tutor for German 1-4 and AP German. I am a licensed K-12 German teacher and have spent time abroad to perfect my language skills. In terms of pedagogical practices, I tend to emphasize full immersion and free speaking in my sessions, but I also work closely with students to see where they most need help and act accordingly. I hope to meet with you soon!",
    tutor: "AP German, German 1-4",
    score: "K-12 Teacher",
    accomplishment: "K-12 Teacher",
    college:
      "",
    degree: "",
  },
  {
    name: "Alexander Cuenca",
    img: "images/alexander.jpg",
    desc: "I'm a UC Irvine graduate with a degree in Mechanical Engineering and 8 years of tutoring experience in Math and Physics. I focus on identifying students' struggles to provide tailored support, fostering deep understanding and confidence to help them excel academically",
    tutor: "AP Physics, AP Math, Algebra, Prealgebra, Spanish 1 & 2",
    score: "",
    accomplishment: " 8 years of tutoring experience in Math and Physics",
    college:
      "B.S. in Mechanical Engineering @ UC Irvine",
    degree: "Mechanical Engineering",
  },

  {
    name: "Todd Johnsen",
    img: "images/todd.jpg",
    desc: "I have worked with students for a cumulative time period of 9 years. I have worked with students from around the world in mathematics, standardized test preparation, biology and chemistry. I obtained a Master of Science in Chemistry (Infochemistry) from ITMO University.",
    tutor: "AP Math, Linear Algebra, AP Statistics, SAT, ACT, LSAT, GMAT",
    score: "",
    accomplishment: " 8 years of tutoring experience in Math and Physics",
    college:
      "M.S. in Chemistry @ ITMO University",
    degree: "Chemistry",
  },

  {
    name: "Brett Chosewood",
    img: "images/brett.png",
    desc:" Hello! I hold a Bachelor's and a Master's in English from Piedmont University and have taught locally, abroad, and online. I also have a strong background in human resources, writing, and communications. I love working one-on-one with students to help them achieve their goals. Their success brings me pride, and we inspire each other in our learning journey. ",
    tutor: "English Language Arts, Creative writing, ESL, SAT/ACT, Essay Editing, College Applications",
    score: "",
    accomplishment: "",
    college:
      "M.S. in English @ Piedmont University",
    degree: "English",
  },
  {
    name: "Alexandra Hunt",
    img: "images/Alexandra.jpg",
    desc: "Post my graduation as salutatorian at Paw Paw High school and my walk across the stage after obtaining my teaching degree from Michigan State University's College of Education in 2011, I began teaching ACT test preparation classes for The Princeton Review. Since then, I've worked with many, many students both in the USA and abroad to secure their goals of higher education and succeed on a variety of undergraduate and graduate exams; while I love test prep in general, the ACT is still my favorite exam to teach and tutor!",
    tutor: "ACT Tutor",
    score: "",
    accomplishment: "10+ Years of Tutoring",
    college: "B.S. in Special Education @ Michigan State",
    degree: "Special Education",
  },
];
