import {
  Box,
  Heading,
  VStack,
  HStack,
  useBreakpointValue,
  Text,
  Image,
  Divider,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import PaymentButton from "../Overall/PaymentButton";

function MiddleSchoolPlan() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });

  if (isDesktop) {
    return (
      <VStack w="100%" mt="5%" justifyContent="center">
        <Heading fontSize="2xl">Middle School Tutoring Packages</Heading>

        <HStack justifyContent="center" w="70%" mt="5%" spacing="10%">
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text mt="5%" textColor="gray">
                1 Hour Class
              </Text>
              <Heading>$50</Heading>
              <PaymentButton amount="50"></PaymentButton>
            </VStack>
          </Box>
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text mt="5%" textColor="gray">
                10 Hour Package
              </Text>
              <Heading>$475</Heading>
              <PaymentButton amount="475"></PaymentButton>
            </VStack>
          </Box>
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text textColor="gray" mt="5%">
                20 Hour Package
              </Text>
              <Heading>$900</Heading>
              <PaymentButton amount="900"></PaymentButton>
            </VStack>
          </Box>
        </HStack>
      </VStack>
    );
  } else if (isMobile) {
    return (
      <VStack w="100%" mt="5%" justifyContent="center">
        <Heading textAlign="center" w="80%" fontSize="3xl">
          Middle School Tutoring Packages
        </Heading>

        <VStack spacing="20px" justifyContent="center" w="70%" mt="5%">
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text mt="5%" textColor="gray">
                1 Hour Class
              </Text>
              <Heading>$50</Heading>
              <PaymentButton amount="50"></PaymentButton>
            </VStack>
          </Box>
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text mt="5%" textColor="gray">
                10 Hour Package
              </Text>
              <Heading>$475</Heading>
              <PaymentButton amount="475"></PaymentButton>
            </VStack>
          </Box>
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text textColor="gray" mt="5%">
                20 Hour Package
              </Text>
              <Heading>$900</Heading>
              <PaymentButton amount="900"></PaymentButton>
            </VStack>
          </Box>
        </VStack>
      </VStack>
    );
  }
  return null;
}
export default MiddleSchoolPlan;
